import { untrack } from 'solid-js';

export const getRefProxyer = (props: { ref?: unknown }) => {
  const refProxyer = <T extends HTMLInputElement | HTMLTextAreaElement>(ref: T) => {
    const proxyElement = new Proxy(ref, {
      get(target, prop, receiver) {
        if (prop === 'value' && typeof target.value === 'string') {
          return (target.value || '').trim();
        }
        return Reflect.get(target, prop, receiver);
      },
    });
    // eslint-disable-next-line solid/reactivity
    typeof props.ref === 'function' ? props.ref(proxyElement) : untrack(() => (props.ref = proxyElement));
  };

  return { refProxyer };
};
